.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #9d50bb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.single {
  padding: 1px 1px;
  margin-top: 40px;
  background: #fcfcfc;
  border: 1px solid #f0f0f0;
}
.single h3.side-title {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  font-size: 20px;
  color: #333;
  text-transform: uppercase;
}
.single h3.side-title:after {
  content: "";
  width: 60px;
  height: 1px;
  background: #ff173c;
  display: block;
  margin-top: 6px;
}

.single ul {
  margin-bottom: 0;
}
.single li a {
  color: #666;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #f0f0f0;
  line-height: 40px;
  display: block;
  text-decoration: none;
}
.single li a:hover {
  color: #ff173c;
}
.single li:last-child a {
  border-bottom: 0;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333333;
}

.menu li {
  float: left;
  list-style-type: none;
}

.menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 1px 1px 1px 1px;
  text-decoration: none;
}

.menu li a:hover {
  background-color: #111111;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
